import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousel = document.querySelector('.carousel-header.swiper');
  const sticky = 60;
  let timer = null;

  function stickyNavbar() {
    const st = window.scrollY;
    if (st >= sticky) {
      document.body.classList.remove('transparent-navbar');
    } else {
      document.body.classList.add('transparent-navbar');
    }
  }

  window.addEventListener('scroll', () => {
    if (timer) {
      window.clearTimeout(timer);
    }

    timer = window.setTimeout(function () {
      stickyNavbar();
    }, 40);
  });

  stickyNavbar();

  if (carousel) {
    new Swiper(carousel, {
      loop: true,
      autoplay: {
        delay: 4000,
      },
      pagination: {
        el: carousel.querySelector('.swiper-pagination'),
        clickable: true,
      },
    });
  }
});
